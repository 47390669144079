import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule, routingComponents } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER } from '@angular/core';
import { AppComponent } from './app.component';
import { AppConfig } from 'src/app/app.config';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CmsService } from './services/cms.service';
import { CmsDataService } from './services/cms-data.service';

export function loadConfig(config: AppConfig) {
  return () => config.load();
}

@NgModule({
  declarations: [AppComponent, routingComponents],
  imports: [BrowserModule, AppRoutingModule, HttpClientModule, FormsModule, ReactiveFormsModule, HttpClientModule],
  providers: [
    AppConfig,
    CmsService,
    CmsDataService,
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfig,
      deps: [AppConfig],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
