import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './containers/home/home.component';
import { TokenGenerationComponent } from './containers/token-generation/token-generation.component';
import { PageNotFoundComponent } from './containers/page-not-found/page-not-found.component';


export const ROUTES: Routes = [
  { path: '', redirectTo: '/stopcomm', pathMatch: 'full' },
  { path: 'stopcomm', component: HomeComponent},
  { path: 'tokenGeneration', component: TokenGenerationComponent},
  { path: '**',   component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [HomeComponent, TokenGenerationComponent, PageNotFoundComponent]
