import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { catchError } from 'rxjs/operators';

import { AppService } from '../../app.service';
import { CmsService } from 'src/app/services/cms.service';
import { CmsDataService } from 'src/app/services/cms-data.service';
import { ClaimsResponse } from 'src/app/shared/claimsResponse.model';
import { Image } from 'src/app/shared/image.model';
import { Texte } from 'src/app/shared/texte.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  error = false;
  email = '';
  cdPresta = '';
  confirmation: string;
  token: string;
  bouton: string;
  logo: string;
  subject: string;
  subjectsuffix: string;
  definition: string;
  marque: string;
  company: string;
  optins: string[];
  errorDesinscription: string;
  libelleError = '';
  tokenIsOK = false;
  textsLoaded = false;
  imagesLoaded = true;
  sent = false;
  className: string;
  icon: string;

  constructor(
    private readonly service: AppService,
    private readonly route: ActivatedRoute,
    private readonly cmsService: CmsService,
    private readonly cmsDataService: CmsDataService
  ) {}

  ngOnInit() {


    this.route.queryParams.subscribe((params) => {
      if (params.token && params.cdPresta) {
        this.cdPresta = params.cdPresta;
        this.token = params.token;
        this.service.getDecryptToken(this.cdPresta, this.token).subscribe(
          (infoToken: ClaimsResponse) => {
            this.email = infoToken.email;
            this.setBrand(infoToken.marque);
            this.setOptin(infoToken.optins);
            this.setCompany(infoToken.company);
            this.loadCmsData();
            if (!infoToken.confirmation || infoToken.confirmation === 'false') {
              this.unsubscribe();
            } else {
              this.tokenIsOK = true;
            }
          },
          () => {
            console.log("getDecryptToken KO");
            this.error = true;
            this.errorProcess();
          }
        );
      } else {
        console.log("params token cdPresta  KO");
        this.setBrand('rena');
        this.setCompany('dc');
        this.error = true;
        this.errorProcess();
      }
      this.loadTextes(null);
    });
  }


  errorProcess(){
    this.setBrand('rena');
    this.setCompany('dc');



    const logoKey = 'stopcomm.logo.' + this.marque + '.' + this.company;
    this.cmsService.getImageByKeyContains(logoKey).pipe( catchError((e) => {
      this.imagesLoaded = false;
      console.log("Récupération IMAGE  catch error par default - "+ logoKey.toLowerCase());
      return e;
    })).subscribe((images: Image[]) => {
      this.logo = this.cmsDataService.getImageUrlByKey(images, logoKey.toLowerCase());

      if(this.logo == null && logoKey != null){
        this.imagesLoaded = false;
        console.log("Tentative de récupération IMAGE dans Strapi ("+ logoKey.toLowerCase()+") aucun résultat");
        console.log("Récupération IMAGE par default - "+ logoKey.toLowerCase());
      }

    });
  }

  unsubscribe() {
    this.service
      .updtateOptins(this.cdPresta, this.token)
      .pipe(
        catchError((e) => {
          console.log("unsubscribe KO");
          this.error = true;
          this.libelleError = this.errorDesinscription;
          return e;
        })
      )
      .subscribe(() => {
        this.tokenIsOK = false;
        this.sent = true;
      });
  }

  private setBrand(marque: string) {

    if (marque == 'NI' || marque == 'niss' || marque === 'NISS') {
      this.marque = 'niss';
    } else {
      this.marque = 'rena';
    }

    this.updateClassName();
  }

  private updateClassName(){
    if(this.marque != null && this.company != null){
      this.className = this.marque+"_"+this.company.toLowerCase();
    }
  }

  private setCompany(company: string) {

    if (company == 'DL') {
      this.company = 'DL';
    } else {
      this.company = 'DC';
    }

    this.company = company;

    this.updateClassName();
  }

  private setOptin(optins: string[]) {
    this.optins = optins;
  }

  private loadCmsData() {
    const opt = this.optins[0].toLowerCase();
    const key = opt +'.'+ this.marque.toLowerCase() + '.' + this.company.toLowerCase();
    this.cmsService.getTexteByKeyContains(key).subscribe((textes: Texte[]) => {
      this.loadTextes(textes);
    });

      //Recuperation TEXTE : Subject
    const subjectKey = 'stopcomm.optin.subject.'+opt;
    this.cmsService.getTexteByKeyContains(subjectKey).pipe( catchError((e) => {
      console.log('Récupération TEXTE par default '+subjectKey+" from strapi.json");
      this.subject = this.cmsDataService.texteDefaultValues[subjectKey];
      return e;
    })).subscribe((textes: Texte[]) => {
      this.subject = this.cmsDataService.getTexteWithKey(textes, subjectKey);
    });

    //Recuperation TEXTE : Suffix
    const subjectSuffixKey = 'stopcomm.optin.subject.suffix.'+opt;
    this.cmsService.getTexteByKeyContains(subjectSuffixKey).pipe( catchError((e) => {
      this.subjectsuffix = this.cmsDataService.texteDefaultValues[subjectSuffixKey];
      console.log('Récupération TEXTE par default '+subjectSuffixKey+" from strapi.json");
      return e;
    })).subscribe((textes: Texte[]) => {
      this.subjectsuffix = this.cmsDataService.getTexteWithKey(textes, subjectSuffixKey);
    });

    //Recuperation TEXTE : Definition
    const definitionKey = 'stopcomm.optin.definition.'+opt;
    this.cmsService.getTexteByKeyContains(definitionKey).pipe( catchError((e) => {
      this.definition = this.cmsDataService.texteDefaultValues[definitionKey];
      console.log('Récupération TEXTE par default '+definitionKey+" from strapi.json");
      return e;
    })).subscribe((textes: Texte[]) => {
      this.definition = this.cmsDataService.getTexteWithKey(textes, definitionKey);
    });




    //Recuperation IMAGE : Logo
    const logoKey = 'stopcomm.logo.' + this.marque.toLowerCase() + '.' + this.company.toLowerCase();

    this.icon = logoKey;
    console.log("Icon - "+ logoKey);
    this.cmsService.getImageByKeyContains(logoKey).pipe( catchError((e) => {
      this.imagesLoaded = false;
      console.log("Récupération IMAGE  catch error 2 par default - "+ logoKey);
      return e;
    })).subscribe((images: Image[]) => {

      this.logo = this.cmsDataService.getImageUrlByKey(images, logoKey);

      if(this.logo == null && logoKey != null){
        this.imagesLoaded = false;
        console.log("Tentative de récupération IMAGE dans Strapi ("+ logoKey.toLowerCase()+") aucun résultat");
        console.log("Récupération IMAGE par default - "+ logoKey.toLowerCase());
      }

    });


  }

  private loadTextes(textes: Texte[]) {
    this.confirmation = this.cmsDataService.getTexteWithSuffix(
      textes,
      'stopcomm.confirmation',
      this.optins, this.marque, this.company
    );
    this.bouton = this.cmsDataService.getTexteWithSuffix(textes, 'stopcomm.bouton', this.optins, this.marque, this.company);
    this.libelleError = this.cmsDataService.getTexteWithSuffix(textes, 'stopcomm.erreur', this.optins, this.marque, this.company);
    this.errorDesinscription = this.cmsDataService.getTexteWithSuffix(
      textes,
      'stopcomm.erreur-desincription',
      this.optins, this.marque, this.company
    );
    this.textsLoaded = true;
  }
}
